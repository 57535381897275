/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {

        $(":file").filestyle({
          buttonName: "btn-primary",
          buttonText: "Escolher Arquivos",
          input: false
        });
        
        if( $(".nav-menu-trigger").length ) {
          $('.nav-menu-trigger').on('click', function(){
            $(this).toggleClass('active');
            $('.responsive-navigation').toggleClass('active');
          });
        }

        if( $(".page-highlight-image img").length ) {
          $(".page-highlight-image img").imageScale({
            align: 'center'
          });
        }

        if( $(".fixed-nav").length ) {
          var topOffset = $(".fixed-nav").offset().top;
          
          $('.fixed-nav').affix({
            offset: topOffset,
            bottom: 0
          });

          $('body').scrollspy({target: '.fixed-nav-wrapper'});
        }

        if( $('.testimonials-slider').length ) {
          $('.testimonials-slider').slick({
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            // fade: true,
            cssEase: 'linear',
            speed: 300,
            prevArrow: '<button type="button" class="slick-nav nav-prev"></button>',
            nextArrow: '<button type="button" class="slick-nav nav-next"></button>'
          });
        }

        if( $('.product-images-slider').length ) {
          $('.product-images-slider').slick({
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            // fade: true,
            cssEase: 'linear',
            speed: 300,
            prevArrow: '<button type="button" class="slick-nav nav-prev"></button>',
            nextArrow: '<button type="button" class="slick-nav nav-next"></button>'
          });
        }

        if( $('#map').length ) {
          function render_map( $el ) {

            // var
            var $markers = $el.find('.marker');

            // vars
            var args = {
              zoom      : 14,
              center    : new google.maps.LatLng(0, 0),
              mapTypeId : google.maps.MapTypeId.ROADMAP,
              scrollwheel: false,
              styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
            };

            // create map           
            var map = new google.maps.Map( $el[0], args);

            // add a markers reference
            map.markers = [];

            // add markers
            $markers.each(function(){
              add_marker( $(this), map );
            });

            // center map
            center_map( map );
          }

          function add_marker( $marker, map ) {

            // var
            var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

            // create marker
            var marker = new google.maps.Marker({
              position  : latlng,
              map     : map
            });

            // add to array
            map.markers.push( marker );

            if( $marker.html() ) {
              // create info window
              var infowindow = new google.maps.InfoWindow({
                content   : $marker.html()
              });

              // show info window when marker is clicked
              google.maps.event.addListener(marker, 'click', function() {
                infowindow.open( map, marker );
              });
            }
          }

          function center_map( map ) {
            // vars
            var bounds = new google.maps.LatLngBounds();

            // loop through all markers and create bounds
            $.each( map.markers, function( i, marker ){
              var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
              bounds.extend( latlng );
            });

            // only 1 marker?
            if( map.markers.length == 1 ) {
              map.setCenter( bounds.getCenter() );
              map.setZoom( 16 );
            }
            else {
              map.fitBounds( bounds );
            }
          }

          $(document).ready(function(){
            $('#map').each(function(){
              render_map( $(this) );
            });
          });
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        
        $('.hero-slider').slick({
          arrows: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4000,
          fade: true,
          cssEase: 'linear',
          speed: 300,
          prevArrow: '<button type="button" class="slick-nav nav-prev"></button>',
          nextArrow: '<button type="button" class="slick-nav nav-next"></button>'
        });

        $("img.slider-backdrop").imageScale();

      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
